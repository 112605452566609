import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types';
import { InlineWidget } from "react-calendly";
import {graphql} from "gatsby";
import { sha256 } from 'js-sha256';

import  Layout from '../components/layout'
import  Seo from '../components/seo'
import {Button, DateTimeInput, Hero, Input, NumberInput, RadioButtons, SectionContainer} from "../components/primitives";
import {FancyCheckboxes} from "../components/composites";
import {shapeCheckboxData} from "../components/utils";

import './contact.scss'

const pageSettings= {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: '00a2ff',
    textColor: '4d5055'
}

const url = 'https://calendly.com/rae-travel/intro';

const API_VERSION = process.env.FACEBOOK_API_VERSION;
const PIXEL_ID = process.env.FACEBOOK_PIXEL_ID;
const TOKEN = process.env.FACEBOOK_ACCESS_TOKEN;

const nightsOption = [
    {value: '3 Nights/2 Days', groupName: 'nights', id: 'threeNights'},
    {value: '5 Nights/4 Days', groupName: 'nights', id: 'fiveNights'},
    {value: '4 Nights/3 Days', groupName: 'nights', id: 'fourNights'},
    {value: '6 Nights/5 Days', groupName: 'nights', id: 'sixNights'},
]

const travelOptions = [
    {name: 'Portugal', groupName: 'where', id: 'beaches', icon: ''},
    {name: 'Morocco', groupName: 'where', id: 'culture', icon: ''},
    {name: 'Colombia', groupName: 'where', id: 'paper', icon: ''},
]

const ContactUsPage = ({data, location}) => {
    const {
        allContentfulCategories,
        allContentfulContactPage
    } = data

    const contactPage = allContentfulContactPage.nodes[0] ?? {};
    const topCategories = allContentfulCategories?.edges ?? [];

    const headerTitle = contactPage?.headerTitle ?? '';
    const headerImage= contactPage?.headerImage ?? '';

    const [showCalendar,setShowCalendar] = useState(false)
    const [prefill,setPrefill] = useState({name: '', email: ''})
    // const calendarRef = useRef(null)

    // async function facebookConversion(email) {
    //     const eventTime = Math.floor(Date.now() / 1000);
    //     const data = JSON.stringify([{
    //         "action_source": "website",
    //         "event_name": "Lead",
    //         "event_time": eventTime,
    //         "user_data": {
    //             "em": [sha256(email)]
    //         }
    //     }])
    //
    //     return await fetch(`https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}&data=${data}`, {
    //         method: "POST",
    //         headers: { "Content-Type": "application/json" },
    //     })
    // }

    // async function postData(e, body) {
    //     const portalId = '21301757';
    //     const formGuide = '9920f3b8-5812-4824-94bd-c37222ebe882';
    //
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(body)
    //     };
    //     const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuide}`, requestOptions);
    //     const data = await response.json();
    //     console.log(data)
    // }

    // const getImportantElements = (name) => {
    //     const inputElements = document.getElementsByName(name)
    //     const array = [];
    //     for(let i=0; inputElements[i]; ++i){
    //         if(inputElements[i].checked) {
    //             array.push(inputElements[i].value)
    //         }
    //     }
    //     return array
    // }

    // async function handleSubmit(event) {
    //     event.preventDefault()
    //
    //     const name = event.target.name.value;
    //     const email = event.target.email.value;
    //     const weddingDate = event.target.weddingDate.value;
    //     const girls = event.target.girls.value;
    //     const importantElements = getImportantElements('importantElements').toString();
    //     const nights = event.target.nights.value;
    //     const budget = event.target.budget.value;
    //     const whereToGo = getImportantElements('whereToGo').toString();
    //     const cleanBudget = Number(budget.replace("$","").replace(",",""));
    //
    //     const body = {
    //         "submittedAt": Date.now().toString(),
    //         "fields": [
    //             {"objectTypeId": "0-1", "name": "full_name", "value": name},
    //             {"objectTypeId": "0-1", "name": "email", "value": email},
    //             {"objectTypeId": "0-1", "name": "wedding_date", "value": weddingDate},
    //             {"objectTypeId": "0-1", "name": "how_long_do_you_want_the_fun_to_last_", "value": nights},
    //             {"objectTypeId": "0-1", "name": "number_of_girls", "value": girls},
    //             {"objectTypeId": "0-1", "name": "what_categories_are_a_priority_", "value": importantElements},
    //             {"objectTypeId": "0-1", "name": "what_is_your_budget_per_girl", "value": cleanBudget},
    //             {"objectTypeId": "0-1", "name": "where_are_you_interested_in_going_", "value": whereToGo}
    //         ],
    //         "context": {
    //             "pageUri": "https://www.rae.travel/contact",
    //             "pageName": "Contact Us"
    //         },
    //     }
    //
    //     try {
    //         setPrefill({name: name, email: email})
    //         setShowCalendar(true)
    //         // calendarRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })
    //         await postData(event, body)
    //         await facebookConversion(email)
    //     } catch (error) {
    //         return error => alert(error)
    //     }
    // }

    return (
        <Layout className='contact-us-page'>
            <Seo title='Contact Us'/>
            <Hero image={headerImage} alt='header' title={headerTitle}/>

            <SectionContainer bgColor='white'>
                <div className='contact-div'>
                    <h3>You Probably Have Some Questions</h3>
                    <div className='info-div'>
                        <p>If you’ve made it this far, you probably have some questions! We understand planning a bachelorette is a process, and you won’t make this decision overnight.</p>
                        <p>I want to make you feel as comfortable as possible. I don’t need any contact information. I won’t follow up with a million emails. I will simply answer your questions.</p>
                        <p>From our experience, the best way to do that is to start a chat. It is quick, informal, and easy. I personally respond to every chat so you can have your questions answered in minutes.</p>
                        <p>You can email me at <a href='mailto:mallory@rae.travel?subject=Bachelorette Questions'>mallory@rae.travel</a> or click the pink chat button in the right hand corner of the screen to get your questions answered.</p>
                        <a className='button' style={{minWidth: '200px', padding: '20px'}} href='mailto:mallory@rae.travel?subject=Bachelorette Questions'>
                            <div className='fancy-div' style={{lineHeight: '45px'}}>Get In Contact</div>
                        </a>
                    </div>
                </div>


                {/*<div className='contact-form' ref={calendarRef}>*/}
                {/*    {!showCalendar &&*/}
                {/*    <form name="contact" onSubmit={(e) => handleSubmit(e)}>*/}
                {/*        <Input label='Your Name' name='name' required placeholder=' '/>*/}
                {/*        <Input label='Your Email' name='email' required type='email' placeholder=' '/>*/}
                {/*        <DateTimeInput label='When Is Your Wedding?' name='weddingDate' required/>*/}
                {/*        <NumberInput label='How Many Girls Are You Expecting To Go On Your Bachelorette?' name='girls'*/}
                {/*                     required/>*/}
                {/*        <FancyCheckboxes label='What Categories Are A Priority?'*/}
                {/*                         optionArray={shapeCheckboxData(topCategories, 'topCategories', 'importantElements', false)}*/}
                {/*                         required/>*/}
                {/*        <RadioButtons label='How Long Do You Want The Fun To Last?' optionArray={nightsOption}*/}
                {/*                      required/>*/}
                {/*        <NumberInput label='What Is Your Budget Per Girl?' prefix='$' name='budget' required/>*/}
                {/*        <FancyCheckboxes label='Where Are You Interested In Going?'*/}
                {/*                         optionArray={shapeCheckboxData(travelOptions, 'whereToGo', 'whereToGo', true)}*/}
                {/*                         required/>*/}
                {/*        <Button isButton type='submit' label='Submit'/>*/}
                {/*    </form>*/}
                {/*    }*/}
                {/*</div>*/}

                {/*<div className='schedule-block'>*/}
                {/*    {showCalendar &&*/}
                {/*    <>*/}
                {/*        <p className='thank-you'>Thanks, we can't wait to talk with you about your bachelorette!</p>*/}
                {/*        <p className='thank-you'>Now schedule a time for us to meet. This is a no commitment chance*/}
                {/*            to learn more about you.</p>*/}
                {/*        <div className='calendar-container'>*/}
                {/*            <InlineWidget url={url} prefill={prefill} pageSettings={pageSettings} styles={{*/}
                {/*                height: '1000px',*/}
                {/*                display: showCalendar ? 'block' : 'none'*/}
                {/*            }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*    }*/}
                {/*</div>*/}
            </SectionContainer>
        </Layout>
    )
}

ContactUsPage.propTypes = {
    data: PropTypes.shape({
        allContentfulContactPage: PropTypes.object,
        allContentfulMainPage: PropTypes.object,
    }),
    location: PropTypes.object
}

export const pageQuery = graphql`
  query ContactQuery {
      allContentfulContactPage {
       nodes {
        headerTitle
         headerImage {
          gatsbyImageData
        }
        contactFormTitle
        contactFormSubtitle {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulCategories(
    sort: {fields: travel_categories___categories___travel_categories___createdAt}
  ) {
        edges {
          node {
             name
            icon {
              gatsbyImageData
            }
          }
        }
      }
  }
`


export default ContactUsPage
